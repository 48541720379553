<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import SignOutPure, {
  SignOutPageSettings,
} from '/~/components/sign-out/sign-out.pure.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useEditablePage } from '/~/composables/cms/use-editable-page'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

const router = useRouter()
const { page } = useEditablePage()
const { cmsConfig } = useCms()
const { providerTitle } = useProvider()
const { user } = useUser()

const settings = computed(() => {
  return page.value?.settings as SignOutPageSettings
})

const buttons = computed(() => {
  return Object.fromEntries(
    Object.entries(settings.value ?? {}).filter(
      ([key]) => key.indexOf('Button') > -1
    )
  ) as Pick<SignOutPageSettings, 'bannerButton1' | 'bannerButton2'>
})

const background = computed(() => getResponsiveValue('background'))
const logo = computed(() => getResponsiveValue('logo'))

const logoAlt = computed<string>(
  () =>
    cmsConfig.value['footer-alt'] ||
    cmsConfig.value['logo-alt'] ||
    `${providerTitle.value} Logo`
)

function getResponsiveValue(settingsKey: 'logo' | 'background') {
  const values = settings.value?.[settingsKey]

  if (!values) return ''

  if (ui.width < ui.breakpoints.xs) {
    return values.mobile || values.tablet || values.desktop
  }

  if (ui.width < ui.breakpoints.md) {
    return values.tablet || values.desktop
  }

  return values.desktop || ''
}

onMounted(() => {
  if (!cmsConfig.value?.['logout-custom-page']) {
    router.push({ name: 'auth-login' })
  } else if (user.value.authorized) {
    router.push({ name: 'home' })
  }
})
</script>

<template>
  <sign-out-pure
    :logo="logo"
    :background="background"
    :logo-alt="logoAlt"
    :buttons="buttons"
  />
</template>
